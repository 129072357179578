html {
    --color-card: #F9F9F9;
    --color-card-primary: #F3F9FF;
    --color-primary: #EB9244;
    --color-text: #322258;
    --color-white: #FFFFFF;
    --color-red: #f44336;
    --color-account-blue: #F3F9FF;
    --color-account-grey: #FCFCFC;
    --color-account-dark-grey: #959595;
}