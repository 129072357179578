footer.footer {
  padding: 56px 32px;
  background: #2A2633;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 66px;
  justify-items: stretch;
  align-items: stretch;

  @media (max-width: 1000px) {
    padding: 56px 16px;
    grid-template-columns: repeat(1, 1fr);
  }

  * {
    text-align: center;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .h3 {
      color: var(--color-white);
    }

    .text {
      display: block;
      color: rgba(255, 255, 255, 0.8);
      max-width: 450px;
    }

    .link {
      max-width: 450px;
      cursor: pointer;
      color: var(--color-primary);
      text-decoration: none;
      margin: 0 10px;
      display: inline-block;

      &:last-of-type {
        // margin-right: 0;
      }
    }

    button {
      margin-top: 40px;
      width: fit-content;
      min-width: 100px;
    }

    .rights {
      min-height: 42px;
    }
  }

}

footer.account {
  padding: 46px 57px;
  margin-left: 350px;
  row-gap: 40px;
  background-color: var(--color-account-grey);

  @media (max-width: 1200px) {
    margin-left: 0;
  }

  @media (max-width: 1000px) {
    padding: 56px 16px;
    grid-template-columns: repeat(1, 1fr);
  }

  .item {
    .text {
      color:  var(--color-account-dark-grey);
      font-size: 13px;
      margin-bottom: 10px;
    }

    .only-desktop {
      @media (max-width: 1000px) {
        display: none;
      }
    }

    .h3 {
      color: var(--color-text);
      font-size: 16px;
      margin-bottom: 9px;
    }

    .link {
      font-size: 13px;
    }

    .rights {
      text-transform: uppercase;
      margin-bottom: 0;
      min-height: 0;
    }

    a.button {
      display: flex;
      align-items: center;
      color: var(--color-primary);
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.04em;
      line-height: 18px;
    }
  }

  .only-mobile {
    display: none;

    @media (max-width: 1000px) {
      display: flex;
    }
  }

}
