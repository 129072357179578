.card-info {
  display: flex;
  margin: 20px;

  @media (max-width: 1200px) {
    margin: 20px 0;
  }
}

.card-label {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-text);

  flex: 1;

  @media (max-width: 450px) {
    flex: 0 1 140px;
  }
}

.card-value {
  color: var(--color-account-dark-grey);
  font-family: 'Proba Pro', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  flex: 0.8;
}
