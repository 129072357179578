html {
  scroll-behavior: smooth;
}

body {
  min-width: 360px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    color: var(--color-text);
    font-family: 'Proba Pro', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;

    b {
      font-weight: bold;
    }
  }

  *::-webkit-scrollbar {
    width: 3px;
    height: 8px;
    cursor: pointer;
  }

  *::-webkit-scrollbar-track {
    background-color: var(--color-primary-light);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
  }

  #root {
    overflow-x: hidden;
  }
}

div.MuiPaper-root {
  background: var(--color-card);
  box-shadow: none;
  border-radius: 0;
  padding: 56px 64px;

  @media (max-width: 1200px) {
    padding: 32px 16px;
  }

  &.MuiMenu-paper {
    padding: 0;
    border: 1px solid #6D6976;
    box-sizing: border-box;
    border-radius: 2px;
    ul {
      padding: 0;
    }

    option {
      cursor: pointer;
      color: var(--color-black);
      padding: 6px 16px;

      &:hover {
        background: var(--color-primary);
        color: var(--color-white);
      }
    }
  }
}

.h2 {
  display: block;
  margin-bottom: 48px;
  font-size: 40px;
  line-height: 50px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 40px;
  }
}

.h3 {
  display: block;
  margin-bottom: 32px;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  @media (max-width: 1200px) {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

span.MuiCheckbox-root {
  padding: 0;
  margin-right: 12px;

  path {
    color: var(--color-text);
  }

  &.Mui-checked {
    path {
      color: var(--color-primary);
    }
  }
}

.margin-bottom {
  margin-bottom: 20px !important;
}

.margin-top {
  margin-top: 10px !important;
}

.center {
  text-align: center;
}

.error-text {
  font-size: 0.75rem;
  color: var(--color-red);
}

.margin-bottom-auto {
  margin-bottom: auto !important;


  @media (max-width: 900px) {
    margin-bottom: 20px !important;
  }
}

.margin-right {
  margin-right: 20px !important;
}

._clickable {
  cursor: pointer;
}

button.MuiButton-root, button.MuiButton-root:hover {
  height: 58px;
  width: 227px;
  min-width: 200px;
  border: none;
  border-radius: 60px;

  &.primary {
    background: var(--color-primary);
  }

  &.secondary {
    background: var(--color-white);

    span {
      color: var(--color-primary);
    }
  }

  span {
    color: var(--color-white);
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  &.Mui-disabled {
    //background: rgba(175, 178, 192, 0.4);
    opacity: 0.7;
  }
}

._contents {
  display: contents;
}

.back-link, .back-link:active, .back-link:hover, .back-link:focus {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  width: fit-content;
  position: absolute;
  top: 56px;
  left: 156px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;

  @media (max-width: 1100px) {
    left: 16px;
    top: 48px;
  }

  span {
    color: var(--color-primary);
  }

  svg {
    margin-right: 12px;
  }
}

.app-container {
  //height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.df_fdc_jcsb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  text-decoration: none;
  cursor: pointer;
  line-height: 18px;
}

a:hover {
  color: var(--color-primary);
}

.centralize {
  justify-content: center;
}

.transfer-part {
  position: relative;
  margin-top: 67px;
  background: var(--color-white);
  padding: 56px 156px;

  @media (max-width: 1200px) {
    margin-top: 60px;
    padding: calc(40px + 40px) 16px 40px;
  }

  .transfer-part__inner {
    width: fit-content;
    margin: auto;

    @media (max-width: 900px) {
      max-width: none;
      width: 100%;
    }
  }

  .header {
    display: block;
    width: 100%;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 56px;
    @media (max-width: 900px) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  hr {
    height: 0;
    border: 0;
    border-bottom: 1px solid #6D6976;
    margin: 32px 0;
  }

  .container {
    form.transfer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .cards {
    display: flex;
    align-items: stretch;

    .card {
      &.one-card {
        width: 60%;

        @media (max-width: 1200px) {
          width: auto;
        }
      }
      width: 340px;
      padding: 32px 24px;
      &:nth-of-type(2) {
        margin-left: 16px;

        @media (max-width: 1200px) {
          margin-left: 0;
        }
      }

      .h3 {
        margin-bottom: 24px;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;

      .card {
        width: calc(100% - 32px);
        padding: 32px 16px;

        &:first-of-type {
          margin-right: 0;
          margin-bottom: 16px;
        }

        .card-header {
          text-align: center;
        }
      }
    }
  }

  .inline-fields {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      margin-right: 24px;

      @media (max-width: 600px) {
        margin-right: 12px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    ._big {
      flex-basis: 70%;
    }

    ._small {
      flex-basis: 30%;
    }
  }


  .agree-part {
    display: flex;
    align-items: center;

    a {
      padding-left: 6px;
    }

    a {
      cursor: pointer;
      color: var(--color-primary);
      font-family: Roboto, serif;
      text-decoration: none;
    }
  }

  .finish-step {
    //height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    svg {
      max-width: 100%;
      margin-bottom: 24px;
    }

    .loader {
      margin-bottom: 48px;
    }

    .code {
      margin-top: 56px;
      text-align: left;
    }

    button {
      margin-top: 56px;
      height: 48px !important;
    }
  }
}

.commission {
  width: 100%;

  hr {
    margin: 0;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
  }
}

.submit {
  margin: 48px auto 0 !important;
  height: 58px !important;
  display: block;
}

.icons {
  margin: 0 auto 32px;
  max-width: 450px;
  min-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 700px) {
    min-width: 260px;
  }
}

div.account-part {
  position: relative;
  margin-left: 350px;
  padding: 56px 40px;

  @media (max-width: 1200px) {
    margin-left: 0;
    margin-top: 100px;
    padding: 80px 16px;
  }

  .back-link, .back-link:active, .back-link:hover, .back-link:focus {
    left: 54px;

    @media (max-width: 1200px) {
      left: 16px;
    }
  }
}

div.account-logo {
  display: flex;
  align-items: center;
}

.dialog-form {
  border-radius: 20px;
  box-shadow: 0 4px 20px var(--color-text);
}

.close-btn {
  align-self: start;
}

div.account-link-container {
  margin-left: 64px;
  margin-top: 165px;

  @media (max-width: 1200px) {
    margin-top: 40px;
  }

  a.link {
    display: flex;
    align-items: center;
    margin-bottom: 81px;
    text-transform: uppercase;
  }

  .link:hover .need-fill,
  .link.currPage .need-fill{
    fill: var(--color-primary);
  }

  .link:hover > span,
  .link.currPage > span{
    color: var(--color-primary);
  }

  .link:hover .need-stroke,
  .link.currPage .need-stroke{
    stroke: var(--color-primary);
  }

  span {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
}

.helpText {
  font-size: 12px;
  line-height: 12px;
  text-align: justify;
  margin-top: 5px;
}

.sumsub-container {
  min-height: 500px;
}
