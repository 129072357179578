.text-input-container {
  width: 100%;

  .label {
    display: block;
    margin-bottom: 4px;
  }

  &:focus-within {
    .text-input {
      border-color: var(--color-primary);
    }
    .label {
      color: var(--color-primary);
    }
  }
  &._error {
    .text-input {
      border-color: #f44336;
    }
    .label {
      color: #f44336;
    }
  }
}

.text-input {
  border: 1px solid #6D6976;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  * {
    border: none !important;
  }

  .MuiFormHelperText-root {
    line-height: 1.2;
  }

  .MuiInputBase-root {
    background: transparent !important;
  }

  .MuiInputBase-multiline {
    padding: 0;
  }

  .MuiInputBase-input, .MuiSelect-select {
    padding: 11px 16px;
    color: var(--color-text);

    &::placeholder {
      color: rgba(109, 105, 118, 0.4);
      opacity: 1;
    }
  }

  .MuiSelect-icon {
    path {
      fill: #6D6976;
    }
  }

  a {
    cursor: pointer;
    color: var(--color-primary);
    margin-right: 16px;
  }

  &._disabled {
    background: rgba(113, 118, 138, 0.2);
    pointer-events: none;
  }
}
