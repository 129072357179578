.contacts-part {
  background: rgba(0, 0, 0, 0.24);

  form {
    outline: none;
    border: none;
    width: 365px;
    max-height: calc(100% - 100px);
    max-width:  calc(100% - 80px);
    padding: 30px 40px 40px;
    background: var(--color-white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    overflow-x: hidden;

    .loader {
      background: transparent !important;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .title {
        padding-top: 10px;
      }

      .empty {
        width: 28px;
      }

      .h3 {
        font-style: normal;
        font-weight: 550;
        font-size: 25px;
        line-height: 20px;

        margin-bottom: 0;
      }

      button.close-btn, button.close-btn:hover, button.close-btn:focus, button.close-btn:active {
        padding: 0;
        min-height: 0;
        width: 28px;
        height: 28px;
        display: block;
        background: transparent;
        box-shadow: none;
      }
    }

    .body-text {
      color: var(--color-account-dark-grey)
    }

    .cancel-button {
      border: 1px solid var(--color-primary);
      border-radius: 60px;
    }

    @media (max-width: 600px) {
      padding: 32px 16px;
    }
  }

  .success-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .loader {
      margin: 56px 0;
    }

    svg {
      max-width: 100%;
    }
  }

}
