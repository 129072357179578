.about-part {
  padding: 70px 16px;

  @media (max-width: 1200px) {
    padding: 56px 16px;
    margin-top: 40px;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: stretch;
    align-items: stretch;
    grid-gap: 16px;

    &:nth-of-type(2) {
      .item, span {
        text-align: center;
      }
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(1, 1fr);

      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }

  .background {
    background: var(--color-card-primary);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 1/3;

    &:nth-child(2) {
      grid-column: 2;
    }

    img {
      max-width: calc(50vw - 48px);
    }

    @media (max-width: 1200px) {
      grid-column: 1 !important;
      grid-row: 1 !important;

      img {
        max-width: 100%;
      }
    }
  }

  .item {
    br {
      content: "";
      margin: 16px;
      display: block;
      font-size: 24%;
    }
  }
}
