div.table{
  width: 100%;
  max-height: 344px;
  overflow: auto;
}

table.transfers-table {
  width: 100%;
  height: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--color-account-grey);

  thead {
    height: 68px;
    background-color: var(--color-account-blue);
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  thead th {
    font-weight: 700;
    padding: 0 20px;
    min-width: 100px;

    @media(max-width: 1200px) {
      padding: 0 5px;
    }
  }

  tbody tr {
    border: 1px solid var(--color-account-grey);
    max-height: 40px;
    height: 40px;

    &.transfer-row {
      max-height: 40px;
    }

    &.transfer-row:hover {
      background-color: var(--color-card);
    }
  }

  td {
    line-height: 20px;
    padding: 0 20px;
    color: var(--color-account-dark-grey);

    @media(max-width: 1200px) {
      padding: 0 5px;
    }
  }
}

button.more {
  margin-top: 38px;
}

.not-visible {
  display: none;
}
