.bad-user {
  font-size: 22px;
  line-height: 22px;
}

.account-part {
  .bad-user {
    margin-top: 10px;
  }
}
