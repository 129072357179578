.profile-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

.profile-dark {
  color: var(--color-text);
}

.profile-orange {
  color: var(--color-primary);
  font-weight: normal;
}

.profile-title {
  font-size: 22px;
  line-height: 22px;
  text-align: center;
}

.profile-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15.81px;
}

.profile-margin-bottom {
  margin-bottom: 55px;
}

.profile-user-info {
  display: inline-block;
}

.profile-margin-bottom-content {
  margin-bottom: 35px;
}
