div.account-menu {
  width: 350px;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: var(--color-account-blue);

  @media (max-width: 1200px) {
    display: none;
  }

  div.account-logo {
    height: 67px;
    margin-left: 64px;
  }
}
